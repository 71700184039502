import { Component, OnInit } from '@angular/core';
import { KindeAuthService } from '../kinde-auth.service';

@Component({
  selector: 'rc-auth-login-callback',
  styles: ['img { margin-bottom: 15rem; }'],
  template: `
    <div
      class="flex flex-column align-items-center justify-content-evenly h-100dvh bg-secondary"
    >
      <img src="/assets/img/logo.svg" width="200" />
    </div>
  `,
  standalone: true,
})
export class KindeAuthLoginCallbackComponent implements OnInit {
  constructor(private authService: KindeAuthService) {}

  public ngOnInit(): void {
    this.authService.handleLoginCallback();
  }
}
